
























































































import { Route } from "vue-router";
import { Component, Vue } from "vue-property-decorator";
import Store from "@/store";
import { ExtendedVessel } from "@/types/Vessel";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import IncidentsModule from "@/store/clients/Incidents.module";

const Incidents = getModule(IncidentsModule, Store);
const Vessels = getModule(VesselsModule, Store);

@Component({
  async beforeRouteEnter(to: Route, from: Route, next): Promise<void> {
    if (/\s/.test(to.path)) {
      next({ path: encodeURI(to.path), replace: true });
    } else {
      await Vessels.updateCurrentVesselById(parseInt(to.params.vesselId));
      next();
    }
  },
  async beforeRouteUpdate(to: Route, from: Route, next): Promise<void> {
    //when switching vessel from the sidebar
    if (from.params.vesselId !== to.params.vesselId) {
      await Vessels.updateCurrentVesselById(parseInt(to.params.vesselId));
      Incidents.updateOpenIncidents();
      if (from.name && from.name !== to.name) {
        //redirect to same tab for different vessel
        next({ path: `${to.redirectedFrom}/${from.name}`, replace: true });
      } else {
        next();
      }
    } else {
      next();
    }
  },
})
export default class Vessel extends Vue {
  created(): void {
    Incidents.updateOpenIncidents();
  }

  get vessel(): ExtendedVessel | null {
    return Vessels.currentVessel;
  }

  get isEnvDevelopment(): boolean {
    const hostnameParts = window.location.hostname.split(".");
    if (hostnameParts[0] === "preprod" || hostnameParts[0] === "test" || hostnameParts[0] === "localhost") {
      return true;
    }
    return false;
  }

  //logic from LongTrendNodeVisibilityProvider.cs
  get showLongTrend(): boolean {
    if (!this.vessel) return false;
    return Boolean(!this.vessel.isDiagnosticActivated && this.vessel.trendDataMinDate && this.vessel.trendDataMaxDate);
  }
  //logic from LoggingHistoryNodeVisibilityProvider.cs
  get showLoggingHistory(): boolean {
    if (!this.vessel) return false;
    return Boolean(this.vessel.logDataMaxDate || this.vessel.logDataMinDate);
  }
}
